import React from "react";
import { render } from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import App from "./containers/App";
import chakraTheme from "./chakraTheme";
import { Helmet } from "react-helmet";
import { ErrorBoundaryComponent } from "./components/error";
import HealthCheck from "./containers/Healthcheck";

//
// This is the frontend JS bundle endpoint. It should load all loaders etc. React boilerplate.
//
// Actual app logic begins in: containers/App.tsx
//

const Ui: React.FC = () => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <ErrorBoundaryComponent wrapInPage>
        <BrowserRouter basename={window.shipmightGlobals.baseUri}>
          <Helmet>
            <title>Shipmight</title>
          </Helmet>
          <HealthCheck>
            <App />
            <div
              style={{
                position: "fixed",
                right: 16,
                bottom: 16,
                fontSize: 12,
                color: "#6B7280",
                backgroundColor: "rgba(55, 65, 81, 0.05)",
                padding: "4px 8px",
                borderRadius: 3,
                zIndex: 1000000,
                userSelect: "none",
                backdropFilter: "blur(10px)",
              }}
            >
              You are viewing a read-only demo of Shipmight. Visit{" "}
              <a
                href="https://shipmight.com"
                style={{ color: "#4B5563", textDecoration: "underline" }}
              >
                shipmight.com
              </a>{" "}
              for more information.
            </div>
          </HealthCheck>
        </BrowserRouter>
      </ErrorBoundaryComponent>
    </ChakraProvider>
  );
};

render(<Ui />, document.querySelector(".app"));
